export default function (Component) {
      Component.options.frontmatter = {
  "published": "2017-11-29T00:00:00.000Z",
  "tags": [
    "vue",
    "accessibility",
    "testing"
  ],
  "title": "Accessible Components: Test Driven Approach",
  "excerpt": "<p>The web is undoubtedly the most vital resource today. Modern websites are built-in small building blocks; <strong>components</strong>. Practices from test-driven software development have employed to automate the correctness of these building blocks.</p>\n"
}
    }